/* Header.css - Styles for the header component */

/* Styling for the header container */
.header {
    background-color: hsl(0, 0%, 100%); /* White background color */
    padding: 15px; /* Padding for spacing */
    text-align: left; /* Align text to the left */
    position: relative; /* Position relative to parent */
    z-index: 10; /* Ensure it stays above other content */
    border-bottom: 1px solid #848484; /* Light gray border at the bottom */
}

/* Styling for the h1 element within the header */
.header h1 {
    font-size: 24px; /* Font size for the header text */
    color: #333; /* Dark gray color for the text */
    margin: 0; /* Remove default margin */
    padding-left: 22px; /* Padding on the left for spacing */
}  

/* Style for the header content container */
.header-content {
    display: flex; /* Flexbox for horizontal layout */
    align-items: center; /* Center items vertically */
}

.language-buttons {
    margin-left: auto; /* Push language buttons to the right */
}

.lang-button {
    background: none; /* No background */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    margin: 0 5px; /* Margin between buttons */
    font-size: 14px; /* Font size */
}

.lang-button:hover {
    text-decoration: underline; /* Underline on hover */
}

/* Style for the logo image */
.header-logo {
    width: 30px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0px; /* Space between logo and text */
    margin-left: 25px; /* Space between logo and text */
}
