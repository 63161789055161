.info-button {
    position: fixed;
    right: 6.6rem;
    top: 1.2%;
    z-index: 10001;
    background-color: #eaeaea00;
    color: #333;
    padding: 0.4rem;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: none;
    cursor: pointer;
  }
  
.info-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.info-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  max-width: 600px; /* Maintain max width */
}

.info-popup h1 {
  text-align: center;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.info-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 1.75rem;
}

