/* Map.css - Specific styles for the Map component */

/* Styling for the map container */
.map-container {
    position: relative; /* Position relative to parent */
    top: 0;
    bottom: 0;
    width: 100%; /* Full width */
    height: calc(100vh - 100px); /* Adjust height to account for header and footer */
    z-index: 0; /* Ensure the map is behind the header */
}

/* Styling for the map wrapper */
.map-wrapper {
    position: relative; /* Position relative to parent */
    z-index: 0; /* Ensure the wrapper does not overlay the header */
}

/* Styling adjustments for the top-right map controls */
.mapboxgl-ctrl-top-right {
    margin-top: 145px; /* Adjust to avoid overlapping with the header */
    margin-right: -45px; /* Adjust to position correctly */
}

/* Styling for the map geocoder control */
.mapboxgl-ctrl-geocoder {
    height: 36px !important; /* Set a fixed height */
    font-size: 14px !important; /* Set font size */
    width: 300px !important;
    min-width: calc(100% - 20px);
}

.mapboxgl-ctrl-geocoder--input {
    width: 100%;
    height: 36px !important; /* Set a consistent height */
    padding: 6px 35px !important; /* Adjust padding to maintain text position */
    font-size: 16px; /* Consistent font size */
    line-height: 24px; /* Maintain consistent line height */
}

/* Use !important only if absolutely necessary */
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search {
    top: 25% !important;
    left: 10px !important;
}

/* Styling for the map popup content */
.mapboxgl-popup-content {
    position: relative; /* Position relative to parent */
    padding: 10px; /* Padding for spacing */
    border-radius: 5px; /* Rounded corners */
    font-size: 14px; /* Set font size */
    width: fit-content;
    padding-right: 15px;
}

/* Hide the default popup arrow */
.mapboxgl-popup-tip {
    display: none; /* Hide the default popup arrow */
}

/* Styling for the color box in the legend */
.color-box {
    display: inline-block; /* Display inline */
    width: 15px; /* Set width */
    height: 15px; /* Set height */
    flex-shrink: 0; /* Prevent shrinking */
    margin-right: 7.5px; /* Margin for spacing */
    margin-bottom: 7.5px;
    border: 1px solid #747474; /* Border color */
    border-radius: 2px; /* Rounded corners */
    vertical-align: middle; /* Align to the top */
}

.percentile-text {
    font-size: 16px;
}

.line-divider {
    border-top: 0.5px solid grey;
    margin: 5px 0px 10px 5px;
}

/* Styling for the popup container */
.popup-container {
    display: flex; /* Use flexbox for layout */
    align-items: flex-start; /* Align items to the start */
    white-space: nowrap; /* Prevent text from wrapping */
    width: auto;
}

.popup-container h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}  

/* Progress bar container */
.progress-container {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 6px;
}

/* Progress bar itself */
.progress-bar {
height: 7px;
border-radius: 2px;
transition: width 0.3s ease;
}

.progress-bar-pardos {
background-color: #a50f15;
}  

.progress-bar-white {
background-color: #08519c; 
}

.progress-bar-black {
background-color: #006d2c;
}

.progress-bar-indigenous {
background-color: #bea40d;
}

.progress-bar-asian {
background-color: #62367b; 
}

.progress-bar-male {
background-color: #083d7f; /* Blue color */
}

.progress-bar-female {
background-color: #d41ac0; /* Pink color */
}