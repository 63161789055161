.popup-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1000;
  width: 90%; /* Ensure the overlay is smaller on mobile screens */
  max-width: 600px; /* Maintain max width */
}

.dot {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: rgb(97, 218, 208);
  border-radius: 50%;
  margin-right: 8px;
}
.popup-content {
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  max-height: 90vh; /* Ensure content doesn't exceed viewport height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  position: relative;
}

.popup-content h2 {
  margin-top: 0;
}

/* Styles for the close button */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.icon-inline {
  vertical-align: bottom; /* Aligns with the text */
  position: relative;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .popup-overlay {
    width: 90%;
    max-width: 90%; /* Reduce max width on mobile */
  }

  .popup-content {
    padding: 15px; /* Adjust padding to fit smaller screens */
    max-height: 80vh; /* Reduce height on smaller screens */
  }

  .popup-close {
    font-size: 20px; /* Slightly smaller X on mobile */
    top: 5px; /* Adjust close button position */
    right: 5px;
  }
}
